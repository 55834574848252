/*
 * Flex UX Framework
 *
 * Filename: breadcrumbs.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.breadcrumb {
    height: 42px;
    line-height: 42px;
    background: #FFF;
    font-family: $secondary-font-face;
    font-size: 14px;
    font-weight: 300;
    padding-left: 24px;
    position: relative;
    //border-top: 1px solid #DBDBDB;
    border-bottom: 1px solid #E6E6E6;
    white-space: normal;
    overflow: hidden;
    text-overflow: clip;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;

    position: relative;
    top: 10px;    

    @include mq("tablet", "min"){
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 35px;
    }

    @include mq("tablet", "max"){
        padding-left: 20px;
    }

    .wrapper{
        width: 100%;
        //max-width: 1175px;

        @include mq("tablet", "min"){
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }
    
    a[name="Country Crock"]{
        pointer-events: none;
    }

    a, span {
        color: $primary-font-color;
        font-weight: 300;
        margin-right: 8px;
        text-decoration: none;
        font-size: 13px;
        font-weight: 300;
    }
}