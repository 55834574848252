/*
 * Flex UX Framework
 *
 * Filename: home-spotlight-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.home {

 	main {
 		//background: #f2f2f2;
 	}
 	
 	.page-body {
 		max-width: 972px;
 		margin:  0px auto auto;
 		padding: 12px;
 		background: inherit;

 		@include mq("phone-wide", "max") {
		    padding: 6px;
		    margin-top: 0;
		}
 	}
 }

.hero-slide{
	display: flex;
	justify-content: center;

	.content{
		display: flex;
		align-items: center;
		/*justify-content: space-around;*/
		justify-content: flex-start;
		width: 100%;

		@include mq('phone-wide', 'max') {
			background-image: url("../images/mobile-spot-item.png");
			background-repeat: no-repeat;
    		background-position: center;
    		background-size: 85%;
		}


		span{
			width: 100%;

				.text-content{
					// padding-bottom: 5vw;
					padding-left: 43px;
					// width: 40%;
					height: 100%;
					display: flex;
					flex-flow: column;
					font-size: 20px;
					color: $brand-color;
					font-weight: 700;
					text-align: center;
					line-height: 1.5em;
					margin-bottom: 15px;

					@include mq("desktop", "min") {
						width:  580px;
					}					

/*					@include mq('phone', 'max') {
						background-image: url("../images/mobile-spot-item.png");
					}*/

					@include mq("phone", "min") {
						text-align:  left;
						margin-left: 30px;
					}						


					@include mq("phone-mid", "min") {
						text-align:  left;
					}					

					@include mq('tablet', 'max'){
						padding-left: 0;
						font-size: 16px;						
					}


					// @include mq("tablet-small", "max") {
					// 	padding-bottom: 8vw;
					// 	padding-left: 10vw;
					// 	width: 60%;
					// }

					h1{
						color: #FFFFFF;
						font-size: 65px;
						line-height: auto;
						//font-size: calc(12px + 3.6vw);
						// font-size: calc(14px + 4vw);
						font-weight: 700;
						/*font-family: 'anton','sans-serif';*/
						/*text-shadow: -1px 1px 4px rgba(0,0,0,0.75);*/
						/*border-bottom: 4px solid #fff;*/
						margin-top: auto;
						margin-bottom: 0;
						width: 300px;
						padding-bottom: 10px;
						/*box-shadow: 0 6px 6px -6px rgba(0,0,0,0.75);*/

						z-index: 999;

						&:first-of-type{
							padding-bottom: 0px;
						}

						@include mq('tablet', 'max') {
							/*margin: auto;*/
							// font-size: calc(100px + 4vw);
						}

						@include mq('phone', 'max') {
							font-size: 12vw;
							text-align: center;
							width: auto;
							padding-bottom: 8px;
						}

						span{
							color: $brand-color;

							&:last-of-type{
								/*color: #D94035;*/
							}
						}

					}

					h2{
						color: #FFFFFF;
						font-weight: 400;
						font-size: 24px;
						line-height: 1.3;
						//font-size: calc(12px + .6vw);
						// font-size: calc(12px + 1vw);
						padding-top: 22px;
						text-shadow: 0 0 6px rgba(0,0,0,0.75);

						@include mq('tablet', 'max') {
							margin: auto;
						}

						@include mq('phone', 'max') {
							padding-top: 10px;
							font-size: calc(24px - 1vw);
						}
					}

					button {
						width: 195px;
						margin-top: 30px;

						@include mq('tablet', 'max') {
							/*margin-left	: auto;*/
							/*margin-left	: 30px;*/
							margin-right: auto;
						}

						@include mq('tablet-small', 'max') {
							margin-top: 3vw;
						}

						@include mq('phone', 'max') {
							/*margin-top: 2vw;*/
							margin-top: 20px;							
						}
					}
				}

		}
	}
}

//.spotlight {
//	.page-header {
//		position: relative;
//		margin-top: 0px;
//		padding-top: $header-height;

//		@include mq("tablet", "max") {
//    		padding-top: 56px;
//		}
//	}
//}



.spotlightHeroImage{
	background-image: url("../images/mobile-spot-item.png");	
    width: 100%;
    height: 363px;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;

    display: none;

 	@include mq('phone-wide', 'min') {
		display: inline;
	}   

	@include mq('desktop', 'min') {
		background-image: url("../images/spot-items.png");	
	}

}


.hero-carousel slick-initialized slick-slider, .home .page-header, .hero-carousel, .hero-slide-1,  {
	@include mq('desktop', 'min') {
	    height: 363px !important;
	    min-height: 363px !important;
	    max-height: 363px !important;		
	}	
}


.contentWrapper{
    margin-top: 40px;
}
