/*
 * Flex UX Framework
 *
 * Filename: _configs.scss
 * Type:     Stylesheet Configs
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


//
// General: Structure
// 

$body-max-width:                    1175px;


//
// General: Color
//

$brand-color:                       #002F6C;
$brand-color-secondary:             #64A70B;
$brand-color-btn-primary:           $brand-color-secondary;
$brand-color-btn-primary-hover:     #418300;
$brand-color-btn-secondary:         $brand-color;
$brand-color-btn-secondary-hover:   #001F55;
$accent-color: 						#009639;
$link-color:						#002F6C;
$link-hover: 						#4D8208;

$primary-font-color:                #535457;
$h1-font-color:                     $brand-color;
$h2-font-color:                     $brand-color;


//
// General: Font
// 

$h1-font-size:                      30px;
$h2-font-size:                      21px;
$primary-font-face:             	'Arial', 'sans-serif';
$secondary-font-face:        	   	'Arial', 'sans-serif';
$drawer-font:                    	$primary-font-face;

//
// Header
//

$header-background:                 #fff;
$header-height:                     112px;
$header-top-height:                 75px;
$header-main-height:                50px;
$top-bar-height:					40px;
$header-mobile-height:              56px;
 
$header-tablet-height:              55px;
$header-phone-height:               55px;
$header-logo-padding:               10px 10px 10px 27px;
$header-minicart-badge-color:       #FFF;
$header-minicart-badge-text-color:  $brand-color;
$header-minicart-badge-border:      none; 
$header-top-link-hover-border:      2px solid rgba(255,255,255,0.5);
$header-icons-color:                #002F6C;
$header-search-font-color:          $primary-font-color;
$header-search-underline-color:     $header-icons-color;


//
// Mobile Drawer Menu
//

$drawer-mobile-background:          #000; //$brand-color;


//
// Footer
//

$footer-background:                 #F8F7EE;
