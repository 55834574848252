/*
 * Flex UX Framework
 *
 * Filename: general.scss
 * Type:     General Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

//Universal Element Styles
html {
    height: 100%;
    color: $primary-font-color;
    border: 0;
    margin: 0;
    padding: 0;
}

body {
    width: 100%;
    height:  auto !important;
    min-height: 100%;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    font-family: $primary-font-face;
    line-height: 1;
    font-size: 13px;
}

input {
    -webkit-appearance: none;
    font-family: $primary-font-face;
    color: $primary-font-color;
    line-height: 1;
    font-size: 13px;
}

// Hide X from search bar for IE/Chrome.
input[type=search]::-ms-clear,
input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    display: none;
}

body, input {
    @include mq("tablet", "max") {
        font-size: 13px;
    }
}


a{
    color:$link-color;
    text-decoration: underline;
    cursor: pointer;
    
    &:hover{
        color: $link-hover;
    }
}

p {
    line-height: 1.5em;
	margin: 0px;
	color: $primary-font-color;
	font-family: $primary-font-face;
	font-size: 14px;
}

img {
    max-width: 100%;
    max-height: 100%;   
}

h1 {
    line-height: 1.15;
    font-family: $primary-font-face;
    color: $h1-font-color;
    letter-spacing: normal !important;;
    font-weight: 700;
    font-size: $h1-font-size;
    margin: 15px 0px;
}

h2 {
    margin: 0;
    line-height: 1.2;
    font-family: $primary-font-face;
    color: $h2-font-color;
    letter-spacing: 0;
    font-weight: 700;
//     text-transform: uppercase;
    font-size: $h2-font-size;

    @include mq("tablet", "max") {
        font-size: 20px;
    }
}

main {
    /*background-color: #F2F2F2;*/
}


@include mq('phone-wide', 'max') {
    .desktop-only {
        display: none !important;
    }
}

@include mq('phone-wide', 'min') {
    .mobile-only {
        display: none !important;
    }
}

//Universal Page Styles
.page-body {
    max-width: $body-max-width;
    padding: 7px 12px 12px;
    margin: 9px auto auto; 


    //margin-bottom: 50px;


    // @include mq("tablet", "max") {
    //     margin-bottom: 50px;
    // }

    // &>h1:first-of-type {
    //     font-weight: 900;
    //     padding-top: 10px;
    //     padding-bottom: 20px;
    //     text-transform: capitalize;
    //     font-size: 30px;
    //     line-height: 34px;
    //     margin-bottom: 20px;
    //     color: $primary-font-color;
    //     margin: 10px 0 0;
    // }
}

.product-card, .product-cell {
    margin: 0 12px 24px;

    @include mq("phone-wide", "max") {
        margin: 6px;
    }

    @include mq("phone", "max") {
        margin: 5px;
    }
}

.grid {
    @include mq("tablet", "min") {
        //margin: 0 !important;
    }
}

.btn--dark {
    border: 1px solid #fff;
    background: black;
    color: white;
}

// .btn--light {
//     -webkit-box-shadow:inset 0px 0px 0px 2px #65B1FB;
//     -moz-box-shadow:inset 0px 0px 0px 2px #65B1FB;
//     box-shadow:inset 0px 0px 0px 2px #65B1FB;
//     background: white;
//     color: #3B3B3B;
// }

.btn--white{
    border: none;
    color: $primary-font-color;
    background: white;

    &:hover{
        background: #EBEBEB !important;
    }
}

.btn--light, .btn--white, .btn-main {
    padding: 0px 30px;
    border-radius: 0px;
    line-height: 0px;
    white-space: nowrap;
    transition: background 0.15s;
    font-family: $primary-font-face;
    border:none;
    background-color: rgba(255, 255, 255, 0.0);
    /*height: 45px;*/
    color: #3B3B3B;
    /*font-weight: 800;*/
}

.btn--dark, .btn--light, .btn--white, .btn-main {
    text-transform: capitalize;
    padding: 0px 30px;
    border-radius: 0px;
    font-size: 16px;
    /*font-weight: 800;*/
    /*height: 45px;*/
    // border-radius: 25px;

    @include mq("phone-wide", "max") {
        font-size: 16px;
    }
}

.btn--primary, .btn--secondary, .checkout-btn, .gift-certificate-button {
    text-transform: capitalize;
    font-size: 16px;
    font-family: $primary-font-face;
    color: #fff;
    /*padding: 0px 30px;*/
    transition: background 0.15s;
    /*height: 45px;*/
    /*font-weight: 800;*/
    border-radius: 20px;

    align-items: center;
    justify-content: center;
    font-size: 17px;

    min-width: 200px;

    /*min-width: 200px;*/

    @include mq("phone-wide", "max") {
        font-size: 16px;
    }
}

.btn--primary, .checkout-btn{
    border: none;
    background-color: $brand-color-btn-primary;
    border-radius: 20px;

    align-items: center;
    justify-content: center;
    font-size: 17px;

    height: 40px;

    /*min-width: 200px;*/


    &:hover {
        background-color: $brand-color-btn-primary-hover!important;
        // color: #000;
    }
}

.btn--secondary, .btn--dark, .btn--light, .gift-certificate-button {
    // -webkit-box-shadow:inset 0px 0px 0px 2px #D52B1E;
    // -moz-box-shadow:inset 0px 0px 0px 2px #D52B1E;
    // box-shadow:inset 0px 0px 0px 2px #D52B1E;
    /*border: 1px solid #D52B1E;*/
    background-color: $brand-color-btn-secondary;
    color: #FFF;
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;

    @include mq("phone-small", "max") {
        font-size: 13px;
    }


    /*min-width: 150px;*/

    &:hover {
        background-color: $brand-color-btn-secondary-hover;
        // color: white;
    }
}

.wishlist, .login, .forgot-password{
    .btn--light {
        // -webkit-box-shadow:inset 0px 0px 0px 2px #D52B1E;
        // -moz-box-shadow:inset 0px 0px 0px 2px #D52B1E;
        // box-shadow:inset 0px 0px 0px 2px #D52B1E;

        background: white;
        color: #535457;
        cursor: pointer;
        border: 1px solid #E6E6E6;

        &:hover{
            background: #E6E6E6;
        }
    }    
}

// Material Design Overrides
.mdl-layout--fixed-drawer>.mdl-layout__drawer-button {
    @include mq("tablet", "min") {
        display: none;
    }
}

.mdl-layout__container {
    pointer-events: none;
}

.gift-certificate-textfield{
    .mdl-textfield__label{
        font-size: 12px;
        text-align: center;
    }
}


.mdl-textfield{

    .mdl-textfield__label{
        // color: #008733 !important;
        &:after {
            background-color: rgba(0,0,0, 0.0) !important;
        }
    }

    &--floating-label.is-dirty, &--floating-label.is-focused{
        .mdl-textfield__label{
            color:$brand-color;
        }
    }

    &__input {
        padding-left: 3px;
        font-family: $primary-font-face;
        font-size: 13px !important;
        color: $primary-font-color;
    }
}

.mdl-tabs__tab .mdl-tabs__ripple-container .mdl-ripple{
    background-color: #000;
}

.mdl-checkbox.is-checked .mdl-checkbox__tick-outline{
    background-color: $brand-color;
}

.checkout{
    .page-body{
        h1:first-of-type{
            //margin-left: 20px;

            @include mq("phone-mid", "max") {
                padding-top: 15px;
                margin-left: 0px;
            }
        }  
    }

    &-main-top {
        background: #fff;
    }

    &-side-top {
        background: #fff!important;
    }
}


/* 
 * Modal Dialogs
 */

.mdl-dialog {
    width: calc(100% - 80px);
    max-width: 760px;
    
    p{
      color:$primary-font-color;
    }

    a{
      color:$brand-color;
    }

    .close-container {
        position: absolute;
        top: 0;
        right: 0;
        margin: 20px;
    }

    .modal-title {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid #efeff1;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        min-height: 55px;
        font-size: 23px;
        text-transform: uppercase;
        line-height: 1.75;
        height: auto;
        .modal-title{

            &__major {
                flex: 1 1 100%;
                align-self: flex-end;
                line-height: 1.15;
                text-align: center;
                font-size: 23px;
                text-transform:capitalize;
                color: $primary-font-color;
                font-weight: 700;
            }
            &__minor {
                flex: 1 1 100%;
                align-self: flex-start;
                line-height: 1.25;
                font-size: 14px;
                font-weight: normal;
                color: $primary-font-color;
                text-transform: capitalize;
                text-align: center;
                margin-top: 10px;
            }
        }

        .mdl-dialog__content{
          padding: 0 0 24px 24px;
          .material-icons{
            font-size: 18px;
          }
          p{
            font-size: 14px;
            line-height: 20px;
          }
        }
    }
      .mdl-dialog__actions{
        display: flex;
        flex-direction: column;
        align-content: center;
        padding: 0;
        align-items: center;

        div{
            display: flex;
            align-content: center;
            justify-content: center;

            .mdl-checkbox{
                display: flex;
                justify-content: center;
                // margin: 0 auto;
                /*max-width: 215px;*/
                width: 100%;
            }
            .mdl-checkbox__label{
                font-size: 14px;
                font-weight: normal;
                color: $primary-font-color;
                text-transform: capitalize;
                line-height: 1.25;
                display: flex;
                align-items: center;
            }
        }

        .mdl-button{
          min-height: 45px;
          height: auto;
        }
      }
}

.select2-dropdown-container {

    .select2 {
        outline-style: none;
    }
    .country-options-container .select2-container, 
    .province-options-container .select2-container {
        flex: 1 1 100%;
        width: 100% !important;
        padding-top: 20px;

        .select2-selection {
            border-left: 0;
            border-right: 0;
            border-top: 0;
            border-radius: 0px;
        }
    }
    .select2-container {
        box-sizing: border-box;
        display: inline-block;
        margin: 0;
        position: relative;
        vertical-align: middle;
    }
}

.dialog-address-modal {
    .select2-selection__arrow {
        top: 21px !important;
    } 
    .mdl-textfield {
        width: 100%;
    }
    .add-button-container {
        display: flex;
        justify-content: flex-end;
    }

    .mdl-dialog__actions {
        @include mq("tablet", "max"){
        //flex-flow: column-reverse;
        justify-content: center;
        }

        .mdl-cell {
            @include mq("tablet", "max"){
                display: flex;
                justify-content: center;
            }
        }
    }

    // .btn--dark{
    //     border: none;
    //     background-color: $brand-color-btn-primary;
    //     box-shadow: none;
    //     &:hover {
    //         background-color: $brand-color-btn-primary-hover!important;
    //     }
    // }
}

.mdl-radio.is-checked {

    .mdl-radio__outer-circle {
        border: 2px solid $brand-color;
    }

    .mdl-radio__inner-circle {
        background: $brand-color;
    }
}



.cookie-policy-wrapper {
    width: 100% !important;
}

.cookie-policy-controls__bottom > button {
    width: auto !important;
}

.cookie-policy-container {
    width: 100% !important;
}

.forgot-password {
    main{
        background: #fff !important;
    }
    .page-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0px auto;
        padding: 12px 12px 50px 12px;
        margin-top: 35px;
        form{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            label{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-top: -25px;

                i{
                    margin-right: 10px;
                }
            }

        }
    }
}

main {
    flex: 1 0 auto;
   
}

.mdl-menu__item{
    &:hover {
        background-color: #f5f5f5;
    }

    a:hover{
        color: $brand-color;
    }
}

.mdl-checkbox.is-upgraded{
    max-width: 225px !important;
}


.attentionBanner{
    height: 48px;
    background: #636569;
    /*width: 100vw;*/
    color: #fff;
    position: relative;
    top: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 1.15em;  
    padding-left: 15px;
    padding-right: 15px;

    @include mq("tablet", "max") {
        top: 65px;
        padding: 15px;
        height: 18px;
    }

}

.attentionBannerIcon{
    margin-right: 15px;
}

.salesPartnerBanner{
    height: 90px;
    background: #002F6C;
    /*width: 100vw;*/
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;    

    color: #fff;
    font-size: 20px;
    line-height: 1.5em;      

    a{
        color: #fff;
        font-size: 20px;
        line-height: 1.5em;           
        text-decoration: underline;
    }  

    @include mq("tablet", "max") {
        height: 90px;
        text-align: center;
        font-size: 14px;

        a{
            font-size:  14px;
        }
    }    
}