/*
 * Flex UX Framework
 *
 * Filename: home-about-us-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


 .home .main-feature {
 	height: 250px;
 	max-width: 948px;
	background-image: url("../images/banner-bg.jpg");
	background-size: cover;
    background-position: 15% 45%;
    margin: 0 auto;
    border-radius: 5px;
		
	@include mq("phone-wide", "max") {
		height: 225px;
	}

	@include mq("phone", "max") {
		height: 175px;
	}

 	> div {
	    width: 25%;
	    /*flex: 1;*/
    	display: flex;
    	flex-flow: column nowrap;
    	background-color: $brand-color-secondary;

    	@include mq("tablet", "max") {
    		 width: 50%;
    	}

        &:last-of-type{

        	width: 75%;
        	
        	/*flex: 3;*/

        	/*margin-top: 5px;*/
        	/*background-color: $brand-color-secondary;*/
		    /*background-image: url("../images/banner-items.png");*/

		    /*background-size: contain;*/
		    
		    background-repeat: no-repeat;
		    
		    /*background-position: bottom center;*/
			
			@include mq('tablet-small', 'max') {
				/*background-image: url("../images/mobile-banner-items.png");*/
				

				/*flex: 1.5;*/

			}

    	}
    }
	.product-info {
    	text-align: center;
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		max-width: 350px;
		width: 100%;
		height: 100%;
		padding-left: 20px;

		@include mq("phone-wide", "max") {
			padding-left: 28px;
			padding-right: 5px;
		}

		@include mq("phone", "max") {
			padding-left: 5px;
		}

		// hr{
		// 	background-color: #C6DD3A;
		// }

	    h1 {
			color: #fff;
			font-family: $primary-font-face;
			font-size: 55px;
			// font-size: calc(15px + 2.012vw);
			font-size: calc(28px + 2vw);
			line-height: 1.15em;
			text-align: left;
			margin: 0;
			font-weight: 700;
			/*text-transform: uppercase;*/
			/*font-family: 'anton', sans-serif;*/

			@include mq("desktop-wide", "min") {
				font-size: 45px;
			}

			// @include mq("phone-wide", "max") {
			// 	font-size: calc(17px + 2.19vw);
			// }
		}

		button{
			max-width: 150px;
			margin-top: 20px;
			height: 40px;
			font-size: 15px;
			// margin: 2% 0px;
			// align-self: flex-start;
			// text-transform: capitalize;
			// background: white;

			@include mq("phone", "max") {
				font-size: 10px;
			}


		}
    }
}

.instock-banner-image{
 	height: 250px;
 	max-width: 948px;
	background-image: url("../images/banner-items.png");
	background-size: cover;
    background-position: 15% 45%;
    margin: 0 auto;	

	@include mq("tablet", "max") {
		background-image: url("../images/mobile-banner-items.png");

		height: 100%;
    	background-position-x: 0px;
	}

}