/*
 * Flex UX Framework
 *
 * Filename: search-content.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */
.search-results {
	main{
		/*background-color: #f2f2f2;*/
	}

    .page-header{
        /*margin-top: 170px;*/
    }
}

