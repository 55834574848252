/*
 * Flex UX Framework
 *
 * Filename: checkout-review-controls.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.checkout-review .checkout-container .checkout-side-bottom p {
    font-size: 13px;
    text-align: center;
    margin-top: 15px;
    line-height: 1.5;
}
