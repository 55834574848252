/*
 * Flex UX Framework
 *
 * Filename: category.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.category {
	
}

.category-list {

	main{
		/*background-color: #F5F5F5;*/
		min-height: calc(100vh - 65px) !important;
		padding-top: 127px;

		@include mq('tablet', 'max'){
			padding-top: 56px;
		}
	}

	.page-header {
		background: #E0F4FD; 
		margin-top: $header-height;
		height: 175px; 
		//display: flex;
		//justify-content: center;
		//align-items: center; 
	}

	.page-body {
		font-family: $primary-font-face;
		font-size: 13px;
		display: flex;
		flex-wrap: wrap;
		max-width: 1180px;
		margin-top: 24px;
		@include mq("tablet", "min") {
			padding: 0px 20px;
		}
		
		.products-container {
			flex-grow: 1;
			flex-basis: calc(100% - 260px);
			order: 4;
			overflow-x: hidden;

			@include mq('tablet', 'max') {
				margin-right: 0;
			}

			@include mq("phone-wide", "max") {	
				margin: 0px -6px 0;
				padding: 0px 9px 9px 9px;
			}
		}

		.grid-container {
    		display: flex;
    		flex-flow: row nowrap;
		}

		@include mq('tablet', 'max') {
			flex-direction: row-reverse;
		}
		@include mq("phone-wide", "max") {
		    padding: 15px 6px 6px 6px;
		    margin-top: 0; 
		}
	}

	.products-header {
    	flex: 3 75%;
    	order: 1;
    	height: 41px;
    	padding-bottom: 5px;
    	margin-bottom: 15px;
    	
    	@include mq('tablet', 'max') {
			align-self: center;
			order: -1;
	    	flex: 2 2 50%;
	    	display: flex;
	    	justify-content: flex-end;
			margin-bottom: 0px;
    		height: 47px;
		}	

    	@include mq('tablet', 'min') {
    		display: flex;
			flex-flow: row;
    		flex-wrap: nowrap;
			flex-wrap: nowrap;
			flex: 4 4 100%;
			align-items: center;
    	}

    	@include mq('phone-small', 'max') {
	    	flex: 2 2 30%;
		}

    	.title {
    		flex: 1 1 50%;
			font-size: 30px;
			height: 48px;
			font-weight: 700;
			font-family: $primary-font-face;
			color: $brand-color;
			@include mq('tablet', 'min') {
				line-height: 62px;
			}

			//visibility: hidden;
    	}

	}

}