/*
 * Flex UX Framework
 *
 * Filename: checkout_payment.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.checkout-payment {
	.continue-button-plp {
		margin-top: 20px;
	}
	.thirdptyft {
		display: none;
	}

	.purchaseOrder {
		display: none;
	}

	.payment-columns {
		background: #fff;
	}

	#question-2, label[for=question-2]{
		display: none;
	} 


 
	// 	input[name="purchaseOrder_0_7292"] {
	// 		display: none;
	// 	}
}