/*
 * Flex UX Framework
 *
 * Filename: checkout-progress.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.checkout-states {
    position: relative;

    z-index: 0;
    display: flex;
    justify-content: center;

    &::before {
    	content: '';
    	position: absolute;
    	z-index: -1;
    	top: 12px;
    	left: 0;
    	border-top: 0;
    	//border-bottom: 1px solid black;
    	border-left: 0;
    	border-right: 0;
    	background: rgba(0,0,0,0);
    	width: 100%;
    	transform: translateY(-50%);
	}
    @include mq("tablet", "max") {
      margin: 15px 0px 20px 0px;
    }

    @include mq("tablet", "min") {
    	margin: 20px 0px 30px 0px;
    }

  	.checkout-state {
    	display: flex;
  		flex-flow: column;
      flex-wrap: nowrap;
  		flex-wrap: wrap;
  		justify-content: center;
  		text-align: center;
      margin: 0px 20px 0px 20px;

      @include mq("tablet", "max") {
        margin: 0px 10px 0px 10px;
      }

      .material-icons{
          margin: 0 auto 5px;
      }

      a {
        flex: 1 1;
        text-decoration: none;
      }

      @include mq("phone", "max") {
        padding-left: 0px;
        padding-right: 0px;
      }

		  &__current {
  			color: #cc0000;
  		}

  		&__current, &__done, &__placeholder {
  			flex: 1 1;
  			width: 24px;
  			height: 24px;
  			min-height: 24px;
  			align-self: center;
        font-size: 24px;
        margin-top: 5px 0px;
  		}

      &__done {
        color: #008000;
      }

  		&__name {
  			flex: 1 1;
  			line-height: 14px;
  			color: $primary-font-color;

        @include mq('phone-smallest','max'){
          font-size: 11px;
          line-height: 13px;
        }
  		}

  		&__placeholder {
  			color: #666;
  		}

	} // .checkout-state

}// .checkout-states

@include mq("tablet", "max") {
  .checkout-container {
      margin: 0px;

    .checkout-main {
      flex: 1 1 100%;
    }
  }
}