/*
 * Flex UX Framework
 *
 * Filename: nav_menu.scss
 * Type:     Component Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

// footer 

.footer-nav-menu {
	margin: 0px;
	li {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		list-style-type: none;
		font-size: 13px;
		/*margin: 0px 13px;*/
		cursor: pointer;
		font-weight: 700;
		height: 45px;

		@include mq("tablet", "min") {
			height: 65px;
		}

		&:hover{
/*			background-color:  #00214D;
			a{
				color:  $brand-color-secondary;
			}*/
		}

		a{
			display: flex;
			justify-content: center;
			align-items: center;
/*			&:hover{
				color:  $brand-color-secondary;
				text-decoration: none;
			}*/
		}
	}

	@include mq("tablet", "max") {
	    padding: 0;
	    padding-top: 25px;
	}
}