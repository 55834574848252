/*
 * Flex UX Framework
 *
 * Filename: myaccount_link.scss
 * Type:     Component Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.my-account{
    .mdl-tabs{
        &__tab {
            padding: 0px 15px;
            transition: background 0.15s;
            font-family: $primary-font-face;
            height: 48px;
            font-size: 13px;
            text-transform: capitalize;
            font-weight: 700;
            color: $primary-font-color;
            
            &:hover{
                background: #F5F5F5;
                color: $link-color;
            }

            &.is-active{     
                color: $primary-font-color !important; 
            }

           @include mq("phone-wide", "max"){
                padding: 0 12px;
            }

            &::after {
           		background: $brand-color !important;
            	height: 2px;
            }
        }

        &__tab-bar{
            // background-color: #DBDBDB;
            border: 1px solid #E6E6E6;


            @include mq("tablet", "max"){    
                margin-top: 6px;
            }

        }
    }
}