/*
 * Flex UX Framework
 *
 * Filename: checkout-addresses-shipping.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.checkout-address-list {
    margin-top: 0;
    padding-top: 0;
    &__item {
    	border-bottom: 1px solid #efeff1;

    	&:last-child {
    		border-bottom: 0;
    	}
    }

    &__description {
        color: $primary-font-color;
        font-family: $primary-font-face;
        line-height: 1.15em;
    	cursor: pointer;
		padding-left: 5px;
		font-size: 14px;
        letter-spacing: 0;
    }
}
