/*
 * Flex UX Framework
 *
 * Filename: home-spotlight-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.cat-spotlight {
	//margin-top: 129px;
	margin-top: $header-height;
	height: 175px;
	background: #009639;
    background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;

	&.upfield{
		background: #009639;
	}

	&.becel{
		background: #fff;
	}

	&.crock{
		background: #58391F;
	}

	&.crockoriginal{
		background: #58391F;
	}

	&.crockplant{
		background: #596345;
	}

	&.flora{
		background: #E6D5AB;
	}

	&.icbinb{
		background: #FFE49D;
	}

	&.violife{
		background: #4A4B4E;
	}

	@include mq('tablet', 'max') {
		height: 150px;
	}

	@include mq('phone-wide', 'max') {
		height: 125px;
	}
	@include mq('phone-small', 'max') {
		height: 100px;
	}

	@include mq('tablet', 'max') {
		margin-top: $header-tablet-height;
	}

	.wrapper{
		width: 100%;
		height: 100%;
		max-width: 1175px;
		display: flex;
		justify-content: center;
		align-items: center;

		img{
			height: calc(32px + 6vw);
			max-height: 125px;
		}
	}
}
