/*
 * Flex UX Framework
 *
 * Filename: register.scss
 * Type:     Template Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.landing {
  .main-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0px;
    padding-right: 35px;
    height: $header-height;
    background: $header-background;

    @include mq("tablet", "max") {
      height: $header-tablet-height;
      padding-right: 49px;
    }

    @include mq("phone-wide", "max") {
      height: $header-phone-height;
      padding-right: 49px;
    }

    &__logo {
      display: flex;
      align-items: center;
      pointer-events: none;
    }

    .logo {
      max-width: 200px;
      max-height: none;
      height: 54px;
      width: 200px;
      margin-left: 12px;

      @include mq("tablet", "max") {
        max-height: 35px;
        width: unset;
        max-width: 140px;
        margin-left: $header-logo-padding;
      }

      @include mq("phone-wide", "max") {
        max-height: calc(#{$header-phone-height} - #{$header-logo-padding});
        margin-left: $header-logo-padding;
        max-width: 125px;
      }
    }
  }

  .spotlight {
    margin-top: $header-height;
    height: 115px;
    /*        background: rgb(0,0,0);
        background: -moz-linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,132,61,1) 100%);
        background: -webkit-linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,132,61,1) 100%);
        background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,132,61,1) 100%);*/
    /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#00843d",GradientType=1);*/
    /*background-size: cover;*/
    background-color: #f8f7ee;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;

    @include mq("tablet", "max") {
      height: 115px;
    }

    @include mq("phone-wide", "max") {
      height: 125px;
    }
    @include mq("phone", "max") {
      height: 100px;
    }

    @include mq("tablet", "max") {
      margin-top: $header-tablet-height;
    }

    .wrapper {
      width: 100%;
      height: 100%;
      max-width: 1175px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      @include mq("tablet-small", "max") {
        justify-content: center;
      }
    }
    .title {
      color: #000;
      font-family: $primary-font-face;
      font-weight: 800;
      font-size: 36px;
      line-height: 1.15em;
      font-size: calc(18px + 1vw);
      padding: 0px 12px;
      text-align: center;
      flex: 1;
      min-width: 300px;
      color: #404041;

      span {
        font-size: 20px;
        font-weight: 400;

        @include mq("phone-mid", "max") {
          font-size: 16px;
          /*                    padding-left: 25px;
                    padding-right: 25px;      */
        }
      }
    }
  }

  .landing-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .wrapper {
      margin: 12px 0px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;

      @include mq("tablet", "max") {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }
      @include mq("phone-wide", "max") {
        padding: 0px 12px;
      }
    }

    &__box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 195px;
      width: 494px;
      margin: 12px;
      padding: 12px;
      background: #fff;

      @include mq("tablet", "max") {
        width: 90%;
      }

      @include mq("phone", "max") {
        margin: 12px 0px;
        padding: 24px 0px;
        width: 98%;
      }

      h1 {
        margin-top: 0px;
        margin-bottom: 24px;
        font-size: 30px;
        text-align: center;
        color: $primary-font-color;
        line-height: 1.15em;
        font-weight: bold;
        padding: 0px 12px;
      }

      p {
        font-size: 14px;
        padding: 20px;
        color: $primary-font-color;
        text-align: center;
      }
    }
  }
  .footer {
    @include mq("tablet", "max") {
      min-height: 125px;
      flex-flow: column nowrap;
      text-align: center;
      padding: 30px;

      #call-link {
        display: inline-flex;

        & > a {
          font-size: 18px;
          font-family: $primary-font-face !important;
        }
      }
    }
  }
}

#allLogos {
  width: 870px;
  height: auto;
  min-width: 300px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;

  @include mq("tablet", "max") {
    width: auto;
    height: auto;
    flex-direction: initial;
  }
}

.allLogosChild {
  width: 278px;
  height: 230px;
  border: 0 solid #ffffff;
  background-color: #ffffff;

  font-size: 22px;
  color: #000;
  text-decoration: none;

  display: flex;
  justify-content: center;

  /*box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.32);*/
  box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.16);

  margin-bottom: 20px;
  margin-right: 10px;
}

.allLogosChildText {
  height: 30px;
  min-width: 200px;
  text-align: center;
}

.divTextButtonWrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: 80px;
  position: relative;
  top: 115px;
}

.divShoppingButtonDiv {
  height: 30px;
}

.corpClass {
  background-color: #2b6cfd;
  color: #fff;

  &:hover {
    background-color: #ffcb00 !important;
    color: #000;
  }
}

.carpClass {
  background-color: #467776;
  color: #fff;

  &:hover {
    background-color: #5eb598 !important;
    color: #1e3333;
  }
}

.wayClass {
  background-color: #ffbe64;
  color: #000;

  &:hover {
    background-color: #ecae52 !important;
    color: #000;
  }
}

.timClass {
  background-color: #7e746c;
  color: #fff;

  &:hover {
    background-color: #39393c !important;
    color: #fff;
  }
}

.shenClass {
  background-color: #001e61;
  color: #fff;

  &:hover {
    background-color: #4599dd !important;
    color: #fff;
  }
}

.cabinetClass {
  background-color: #c2874d;
  color: #000;

  &:hover {
    background-color: #9b6c3e !important;
    color: #fff;
  }
}

.1951logo {
  width: 40%;
}

.disabledDiv {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.disabledLink {
  pointer-events: none;
}
