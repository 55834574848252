/*
 * Flex UX Framework
 *
 * Filename: register.scss
 * Type:     Template Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.register{

	main {
		background: #fff;

		.login-box .mdl-grid .mdl-textfield__label{
			text-align: center;
			padding-left: 0;
		}
	}

	.page-header{
		/*margin-top: 170px;*/
	}
}