/*

 * Flex UX Framework

 *

 * Filename: pagination.scss

 * Type:     Partial Stylesheet

 * 

 * Copyright (C) 2019 Geiger, All Rights Reserved

 */



.pagination{
	display: flex;
	justify-content:center;
	color: $brand-color-secondary;
	margin: 30px 0px;

	.pagination-left{
		display: flex;
	}

	.pagination-numbers{
		display: flex;
		list-style-type: none;
		margin: 0px;
		align-items: center;
		padding: 0px;
		text-align: center;

		li{
			padding: 0 2px;
			width: 35px !important;
			height: 35px !important;
			line-height: 35px !important;
			margin-right: 4px;

			a{
				text-decoration: none;
				color: #000;
				font-weight: bold;
			}

			&.is-active {
				color: #fff!important;
				background: $brand-color!important;

				a {
					color: #fff;
				}
			}

			&:hover {
				background: $brand-color!important;

				a {
					color: #fff;
				}
			}

		}
	}

	.material-icons {
		color: $brand-color;
	}

}