/*
 * Flex UX Framework
 *
 * Filename: item-romance-desktop.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.product-description-tabs {
    padding: 10px 20px;
    // border-radius: 5px;
    margin-top: 25px;
	background: white;
	box-sizing: border-box;
	border: 1px solid #e6e6e6;
	border-radius: 5px;

	@include mq("tablet", "max") {
	    padding: 10px 20px;
	    margin-top: 10px;
	}

	.mdl-tabs {

    	&__tab{

    		padding: 0px 15px;
		    transition: background 0.15s;
		    font-size: 16px;
		    font-weight: 700;
		    border: 1px solid #F2F2F2;
		    background-color: #FFF;
		    height: 45px;
		    height: 48px;
		    font-size: 15px;
		    font-weight: 700;
		    font-family: $secondary-font-face;
		    border: 0;

    		@include mq("phone-wide", "max"){
    			padding: 0 12px;
    		}

			&-bar {
	    		justify-content: flex-start;
	    		font-size: 16px;

	    		a {
	    			font-weight: 700;
	    			color: $primary-font-color!important;
	    			padding: 0;
	    		}
	    	}

	    	&:after{
	    		background: $brand-color-secondary !important;
	    		height: 2px;
	    	}
    	}

    	&__panel {
    		font-family: $primary-font-face;
			line-height: 1.8;
			letter-spacing: 1px;
			padding: 20px 0px 30px;

			@include mq("tablet", "max"){
				line-height: 2;
			}

			p {
				line-height: 1.5em;
				margin: 0px;
				color: #000000;
				font-family: $primary-font-face;
				font-size: 14px;
				color: $primary-font-color!important;
			}
    	}
	}
}